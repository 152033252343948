module.exports = {
  siteMetadata: {
    title: `Unpackables`,
    siteUrl: `https://www.unpackables.com`,
    description: `A community for self-learners to share and discover their journeys towards learning something new.`,
    author: `Unpackables.com`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sass`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#000`,
        display: `minimal-ui`,
        icon: `src/images/unpackables.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-source-contentful`,
      options: {
        //spaceId: process.env.CONTENTFUL_SPACE_ID,
        //accessToken: process.env.CONTENTFUL_ACCESS_TOKEN
        spaceId: `iyqxhlk14jb8`,
        accessToken: `bxPShueMadBuSmFk5ohUTNV6lNjNbow1CFGRHjHy0cQ`,

        //process.env.CONTENTFUL_ACCESS_TOKEN,
        //Learn about environment variables: https://gatsby.dev/env-vars
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,

    // material-ui via gatsby
    {
      resolve: `gatsby-theme-material-ui`,
      options: {
        webFontsConfig: {
          fonts: {
            google: [
              {
                family: "Inter",
                variants: ["300", "400", "600"],
              },
              // {
              //   family: "Space Mono",
              //   variants: ["400", "700"],
              // },
              {
                family: "IBM Plex Mono",
                variants: ["300", "400"],
              },
              {
                family: "Kumbh Sans",
                variants: ["300", "400", "700"],
              },
            ],
          },
        },
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          "G-FXKBEL02XQ", // Google Analytics / GA
          // "AW-CONVERSION_ID", // Google Ads / Adwords / AW
          // "DC-FLOODIGHT_ID", // Marketing Platform advertising products (Display & Video 360, Search Ads 360, and Campaign Manager)
        ],
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: true,
          // Setting this parameter is also optional
          respectDNT: true,
          // Avoids sending pageview hits from custom paths
          // exclude: ["/preview/**", "/do-not-track/me/too/"],
        },
      },
    },
  ],
};
