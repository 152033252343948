import React, { Suspense } from "react";
import { graphql } from "gatsby";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SEO from "../components/seo";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

// Styles
import "./tripTemplate.scss";

// Material-UI imports
import { Link } from "gatsby-theme-material-ui";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { siteMetadata } from "../../gatsby-config";

//on demand load of the JS component so windows doesn't get triggered from build
const ReactTinyLink = React.lazy(
  () =>
    new Promise((resolve) => {
      const { ReactTinyLink: component } = require("react-tiny-link");
      resolve({ default: component });
    })
);

const lightboxoptions = {
  settings: {
    overlayColor: "rgba(0, 0, 0, 1)",
    slideAnimationType: "both",
    slideSpringValues: [500, 100],
    lightboxTransitionSpeed: 0.2,
    hideControlsAfter: false,
  },
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{node}</Text>,
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <Text>{node}</Text>,
  },
  renderNode: {
    "embedded-asset-block": (node) => {
      const alt = node.data.target.fields.title["en-US"];
      const url = node.data.target.fields.file["en-US"].url;
      return <img src={url} alt={alt} />;
      //need help on making the size correct
    },
    [INLINES.HYPERLINK]: (node, next) => {
      const url = node.data.uri;
      return (
        <Box>
          {typeof window !== "undefined" ? (
            <Suspense fallback={null}>
              <ReactTinyLink
                cardSize="small"
                showGraphic={true}
                maxLine={2}
                minLine={1}
                url={url}
              />
            </Suspense>
          ) : null}
        </Box>
      );
    },
  },
};

const TripTemplate = (props) => {
  const trip = props.data.contentfulTrip;
  const {
    title: { title },
    author,
    level,
    category,
    wayposts,
  } = trip;

  return (
    <React.Fragment>
      <SEO
        title={
          trip.author.name + " on " + siteMetadata.title + ": " + trip.title
        }
      />
      <section>
        <Container maxWidth="sm">
          <Box className="back-link">
            <Link to="/app" color="secondary">
              <Box component="span">
                <ArrowBackIosIcon fontSize="small" />
              </Box>
              <Box component="span">Back</Box>
            </Link>
          </Box>
          <Box className="content-item" mt={2}>
            <Box className="user-avatar">
              <Link to={`/profile/${trip.author.slug}`}>
                {trip.author.profilePhoto ? (
                  <img
                    src={trip.author.profilePhoto.file.url}
                    alt="user avatar"
                  />
                ) : (
                  <img
                    src="https://images.ctfassets.net/iyqxhlk14jb8/4zo0lWV0x7EKtTrypYAIay/2435a984ffc7013b3484521a080f7e77/default-avatar.png"
                    alt="user avatar"
                  />
                )}
              </Link>
            </Box>
            <Box className="content-item-info">
              <Box className="content-item-meta">
                <Box component="span">
                  <Link
                    to={`/profile/${trip.author.slug}`}
                    className="author-name"
                  >
                    {trip.author.name}
                  </Link>
                </Box>
                <Box component="span" className="separator">
                  &#183;
                </Box>
                <Box component="span">{trip.category} </Box>
                <Box component="span" className="separator">
                  &#183;
                </Box>
                <Box component="span">{trip.level} </Box>
              </Box>
              <Box className="content-item-title">
                <Typography variant="h1">{trip.title}</Typography>
              </Box>
            </Box>
          </Box>
          <Grid item xs={12}>
            <ol className="waypost-list">
              {trip.wayposts &&
                trip.wayposts.map((w, i) => (
                  <li key={i}>
                    <Box component="span" className="list-connector"></Box>
                    <Box className="journey-list-item">
                      {documentToReactComponents(w.lesson.json, options)}
                      <SimpleReactLightbox>
                        <SRLWrapper options={lightboxoptions}>
                          <Box className="lightbox-images-wrapper">
                            {w.photos &&
                              w.photos.map((photo, i) => (
                                <a
                                  href={photo.file.url}
                                  data-attribute="SRL"
                                  className="lightbox-thumbnail"
                                >
                                  <img
                                    key={i}
                                    src={photo.file.url}
                                    alt={photo.file.name}
                                  />
                                </a>
                              ))}
                          </Box>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </Box>
                  </li>
                ))}
            </ol>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TripTemplate;

export const query = graphql`
  query($id: String!) {
    contentfulTrip(id: { eq: $id }) {
      title
      level
      category
      author {
        id
        slug
        name
        profilePhoto {
          title
          file {
            url
          }
          resize(height: 10, width: 10) {
            src
          }
        }
      }
      wayposts {
        id
        lesson {
          json
        }
        photos {
          file {
            url
          }
        }
      }
    }
  }
`;
